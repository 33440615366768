import React from "react"

export default function Search({ setnodehtmlsearch, htmlfile, nodehtml }) {
  const searchquerry = e => {
    if (e.target.value.toLowerCase() === "") {
      setnodehtmlsearch()
    } else {
      htmlfile= e.target.value.toLowerCase();
      try {
        var h_tag = nodehtml
          .join(" ")
          .split("id=" + e.target.value.toLowerCase().trim())[0]
          .split("<h")
        h_tag = h_tag[h_tag.length - 1]

        setnodehtmlsearch(
          "<h" +
            h_tag +
            ">" +
            nodehtml
              .join(" ")
              .split("id=" + e.target.value.toLowerCase().trim())[1]
              .split(">")
              .slice(1)
              .join(">")
        )
      } catch (e) {
        setnodehtmlsearch(
          "<p class='noresultfind'>No search result found !</p>"
        )
      }
    }
  }
  return (
    <div className="Search">
      <input
        className=""
        onChange={searchquerry}
        type="text"
        placeholder="Search"
      />
      <i className="fa fa-search" />
    </div>
  )
}
