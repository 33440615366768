/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import Footer from "../Common/footer"
import { useDispatch } from "react-redux"
import Search from "./search"
import descriptionredner from "./navigation"
import { activeitems } from "../../state/createStore"
const Description = ({ description }) => {
  const htmlfile = "";
  const [nodehtml, setnodehtml] = useState()
  const [nodehtmlsearch, setnodehtmlsearch] = useState()
  const dispatch = new useDispatch()

  useEffect(() => {
    setnodehtml(descriptionredner(description))
    setTimeout(() => {
      var element = document.getElementsByTagName("h1")

      var intersectionObserver = new IntersectionObserver(entries => {
        let [entry] = entries
        if (entry.isIntersecting) {
          dispatch(activeitems(entry.target.id))
        }
      })
      for (var x of element) {
        intersectionObserver.observe(x)
      }
    }, 2000)
  }, [description, dispatch])

  return (
      <div className="description">
        <div className="content_des">
          <Search
            setnodehtmlsearch={setnodehtmlsearch}
            htmlfile={htmlfile}
            nodehtml={nodehtml}
          />
          {nodehtmlsearch
            ? ReactHtmlParser(nodehtmlsearch)
            : ReactHtmlParser(nodehtml)}
        </div>
        <Footer />
      </div>
  )
}
export default Description