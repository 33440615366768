import React from "react"
import { Link } from "gatsby"
import SocialIcons from "../Common/socialIcons"
const Footer = () => {
  return (
      <footer className="footer content has-text-centered">
        <div className="footer-color-position">
          <SocialIcons />
            <a
              href="https://tageoforce.com"
              aria-label="T.A. Geoforce"
              target="_blank"
              rel="noopener noreferrer"
            >
              &copy; T.A. Geoforce
              </a>{" "}|{" "}
            <Link to="/terms">Terms &amp; Conditions</Link>
            {" "}|{" "}
            <Link to="/privacy">Privacy Policy</Link>
        </div>
    </footer>
  )
}

export default Footer
