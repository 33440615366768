export default function description(description) {
  let header
  var finalhtml1 = []
  var tags = description.split("</h1>")
  if (tags.length > 0) {
    for (var c = 0; c < tags.length; c++) {
      if (tags[c].includes("<h1")) {
        var heading2 = tags[c]
          .split("<h1>")[1]
          .replace(/\./g, "")
          .trim()
          .split(" ")
          .join("-")
          .toLowerCase()
        if (c === 0) {
          var elem = document.getElementById("GoTopId")
          elem.href += heading2
          header = heading2
        }
        var customHeader2 =
          "<h1 id=" + heading2 + ">" + tags[c].split("<h1>")[1] + "</h1>"
        finalhtml1.push(tags[c].split("<h1>")[0] + customHeader2)
      } else {
        finalhtml1.push(tags[c])
      }
    }
  } else {
    finalhtml1 = description
  }

  // h2 splitting
  var finalhtml2 = []
  tags = finalhtml1.join(" ").split("</h2>")
  if (tags.length > 0) {
    for (c = 0; c < tags.length; c++) {
      if (tags[c].includes("<h2>")) {
        heading2 = tags[c]
          .split("<h2>")[1]
          .replace(/\./g, "")
          .trim()
          .split(" ")
          .join("-")
          .toLowerCase()
        if (heading2 === header) {
          heading2 = heading2 + "-1"
        }
        customHeader2 =
          "<h2 id=" + heading2 + ">" + tags[c].split("<h2>")[1] + "</h2>"
        finalhtml2.push(tags[c].split("<h2>")[0] + customHeader2)
      } else {
        finalhtml2.push(tags[c])
      }
    }
  } else {
    finalhtml2 = finalhtml1
  }

  // h3 splitting
  var finalhtml3 = []
  tags = finalhtml2.join(" ").split("</h3>")

  if (tags.length > 0) {
    for (c = 0; c < tags.length; c++) {
      if (tags[c].includes("<h3>")) {
        heading2 = tags[c]
          .split("<h3>")[1]
          .replace(/\./g, "")
          .trim()
          .split(" ")
          .join("-")
          .toLowerCase()
        customHeader2 =
          "<h3 id=" + heading2 + ">" + tags[c].split("<h3>")[1] + "</h3>"
        finalhtml3.push(tags[c].split("<h3>")[0] + customHeader2)
      } else {
        finalhtml3.push(tags[c])
        // setnodehtml(finalhtml3)
      }
    }
  } else {
    finalhtml3 = finalhtml2
    // setnodehtml(finalhtml3)
  }
  return finalhtml3
}
