/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Menu from "./menu"
import Description from "./description"

const Doc = ({ description, menu }) => {
  return (
    <>
      <Menu menu={menu} />
      <div className="docs">
        <Description description={description} />
        <a id="GoTopId" href="#">
          <i className="scrollup-docs fa fa-chevron-up"></i>
        </a>
      </div>
    </>
  )
}
export default Doc