import React from "react"

import Docs from "../components/docs"
import Layout from "../components/Layout"

export default function Template({ pageContext }) {
  return (
    <Layout className="docs_page" titlePage="Docs">
      <Docs
        description={pageContext.alldata.html}
        menu={pageContext.alldata.headings}
      />
    </Layout>
  )
}
