import React from "react"
import config from "../../../config"

export default function SocialIcons() {
  return (
    <>
      {config.socialLinks.map((social, counter) => {
        const { icon, name, url } = social
        return (
            <a
              key = {counter}
              href={url}
              className={"fa icon " + icon}
              aria-label={name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="label">{name}</span>
            </a>
        )
      })}
      <br></br>
    </>
  )
}
