import React, { useState, useEffect } from "react"
import logo from "../../assets/img/logo.png"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
const Menu = ({ menu }) => {
  const [openMenu, setOpenMenu] = useState("");
  const [openContent, setOpenContent] = useState("");
  let DoOnce = 0, headline;
  const headings = useStaticQuery(graphql`
    query HeaderQuery {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___version }) {
        nodes {
          frontmatter {
            version
          }
        }
      }
    }
  `)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const bulmaCollapsible = (() => {
        if (typeof window !== 'undefined') {
            return require('@creativebulma/bulma-collapsible')
        }
      })()
      bulmaCollapsible.attach('.is-collapsible');
    }
  }, [])
  const convertStringForLink = value => {
    const r = value
      .replace(/\./g, "")
      .trim()
      .split(" ")
      .join("-")
      .toLowerCase()
    return r
  }
  const toggleMenuNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 1023 && openContent === "")
      {
        setOpenMenu("is-active");
        setOpenContent("is-active");
      }
      else
      {
        setOpenContent("");
        setOpenMenu("");
      }
    }
  }

  const toggleMenuNavbarForMenu = (menu, counter ) => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 1023 && menu[counter + 1].depth === 1)
      {
        setOpenContent("");
        setOpenMenu("");
      }
    }
  }
return (
<nav className="docs-nav-color">
  <div onClick = {() => toggleMenuNavbar()} className={"navbar-burger position " + openMenu} aria-label="menu" aria-hidden="true">
    <i className="burger-docs fa fa-chevron-down" aria-hidden="true"></i>
  </div>
  <div className={"navbar-menu " + openContent}>
    <div className="menu">
      <img src={logo} alt="" className="logo-docs" />
      <div id={"accordion"}>
        {menu.map((data, counter) => {
        return (
          <div key = {counter}>
            {data.depth === 1 && (
            <article className="message">
              {DoOnce === 0
              ? ((DoOnce = 1), (headline = data.value), null)
              : null}
              <div className="message-header">
                <a onClick={ () => toggleMenuNavbarForMenu(menu, counter)} href={"#" + convertStringForLink(data.value)}>
                  <div data-target={"collapsible-message-accordion_" + counter} data-action="collapse">
                  {" "}
                  {menu[counter + 1] &&
                  menu[counter + 1].depth > 1 ? (
                  <>
                  {" "}
                  {data.value}
                  <i className="position-icon fa fa-angle-down" aria-hidden="true"></i>
                  </>
                  ) : (
                  data.value
                  )}
                  </div>
                </a>
              </div>
              <nav id="sub_title">
                {menu[counter + 1] &&
                menu[counter + 1].depth > 1 && (
                <div id={"collapsible-message-accordion_" + counter} className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                  <div className="message-body-content">
                    {(() => {
                    const childitems = []
                    let changelog = ""
                    for (
                    var x = counter + 1;
                    x < menu.length;
                    x++
                    ) {
                    if (menu[x].depth === 1) {
                    break
                    } else if (menu[x].depth > 1) {
                    if (menu[x].value === headline) {
                    changelog = "-1"
                    } else {
                    changelog = ""
                    }
                    childitems.push(
                      <a
                      key={x + 1}
                      href={
                      "#" +
                      convertStringForLink(
                      menu[x].value
                      ) +
                      changelog
                      }
                      onClick={ () => toggleMenuNavbar()}
                      >
                      {menu[x].value}
                      </a>
                    )
                    }
                    }
                    return childitems
                  })()}
                  </div>
                </div>
                )}
              </nav>
            </article>
            )}
          </div>
        )
        })}
        <article className="message">
          <div className="message-header">
            <a href="#collapsible-message-accordion_40" data-action="collapse" >
              <p>Versions<i className="position-icon fa fa-angle-down" aria-hidden="true"></i></p>
            </a>
          </div>
          <div id="collapsible-message-accordion_40" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
            <div className="message-body-content">
              {headings.allMarkdownRemark.nodes.map((heading, counter_) => {
              return (
                <Link
                key={counter_}
                to={"/docs/" + heading.frontmatter.version}
                >
                {heading.frontmatter.version}
                {counter_ === 0 && " (Latest)"}
                </Link>
                )
              })}
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</nav>
)
}
export default Menu